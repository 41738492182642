import { ResponsiveRadar } from "@nivo/radar";

export const ResponsiveRaderTopSpeed = (matches_players) => {
    return (
        <ResponsiveRadar
            data={matches_players.filter(player => player.sprints > 0).map(matches_player => {
                return {
                    name: matches_player.name,
                    sprints: matches_player.sprints,
                    'Top Speed (Max)': matches_player.top_speed,
                    'Top Speed (Avg)': matches_player.avg_top_speed,
                }
            })}
            keys={
                ['Top Speed (Max)', 'Top Speed (Avg)'] // 'sprints', 
            }
            indexBy="name"
            valueFormat=">-.2f"
            margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
            borderColor={{ from: 'color' }}
            gridLabelOffset={36}
            dotSize={10}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            colors={{ scheme: 'nivo' }}
            blendMode="multiply"
            motionConfig="wobbly"
            legends={[
                {
                    anchor: 'top-left',
                    direction: 'column',
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#999',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />);
}