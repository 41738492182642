import { useTheme } from "@mui/material";
import React from "react";
import {
  DateInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

export const EditFormTab: React.FC = (props) => {
  const theme = useTheme();
  return (
    <FormTab
      {...props}
      sx={{
        "& .RaTextField-root": {
          width: "300px",
        },
        "& .RaFunctionField-root": {
          width: "300px",
          color: "primary",
        },
      }}
      label="resources.matches.tabs.edit"
      path="edit"
    >
      <ReferenceInput
        label="resources.practices.fields.team_id"
        source="team_id"
        reference="teams"
        filter={{ currentSeason: true }}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {/* <ReferenceInput
        label="resources.matches.fields.opponent"
        source="club_id"
        reference="clubs"
        filterToQuery={(searchText: any) => ({ q: searchText })}
        perPage={5}
      >
        <AutocompleteInput
          fullWidth
          noOptionsText="-"
          shouldRenderSuggestions={(val: any) => {
            return val.trim().length > 2;
          }}
          optionText="name"
        />
      </ReferenceInput> */}
      <DateInput label="resources.matches.fields.date" source="date" />
      <TextInput
        label="resources.matches.fields.matchTime"
        source="matchTime"
      />
      <TextInput
        label="resources.matches.fields.meetingTime"
        source="meetingTime"
      />
      <NumberInput
        label="resources.matches.fields.gameTime"
        source="gameTime"
      />
      <TextInput
        label="resources.matches.fields.homeTeam"
        source="homeTeam"
        disabled={true}
      />
      <TextInput
        label="resources.matches.fields.guestTeam"
        source="guestTeam"
        disabled={true}
      />
      <TextInput label="resources.matches.fields.venue" source="venue" />
      <TextInput label="resources.matches.fields.street" source="street" />
      <TextInput label="resources.matches.fields.city" source="city" />
      <TextInput
        label="resources.matches.fields.placeType"
        source="placeType"
      />
      {/* <TextInput label="resources.matches.fields.area" source="area" /> */}
      {/* <BooleanInput label="resources.matches.fields.isHome" source="isHome" /> */}
      {/* <SelectInput label="resources.matches.fields.venue" source="venueChoice" choices={choiceVenues} /> */}
      {/* <LinkedTeamInput source="team" /> */}
    </FormTab>
  );
};
