/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { cloneElement, Fragment } from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    TextField,
    SingleFieldList,
    ChipField,
    FunctionField,
    TextInput,
    Filter,
    List,
    SelectInput,
    ReferenceArrayField,
    ListContextProvider,
    useListContext,
    CreateButton,
    sanitizeListRestProps,
    TopToolbar,
    ResourceContextProvider,
    FilterProps,
    ReferenceField,
    usePermissions,
    DatagridConfigurable,
    SelectColumnsButton,
    useGetIdentity,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import {
    choicesFoot,
    choicesAgegroup,
    convertBirthdateToAge,
    choicesApplicationType,
    choicesRatings,
} from '../../types';
import { NameField } from '../../components/fields/NameField';
import { FootField } from '../../components/fields/FootField';
import BulkdPlayerActionButtons from './buttons/BulkPlayerActionButtons';
import PlayerMobileGrid from './PlayerMobileGrid';
import { AvatarField } from '../../components/fields/AvatarField';
import { TalentdayScoutingDialog } from './TalentdayScoutingDialog';
import { useLocation } from 'react-router-dom';
import { PositionChipField } from '../../components/fields/PositionChipField';

const PlayerListActions = props => {
    const { className, exporter, filters, maxResults, hasCreate, ...rest } =
        props;
    const { resource, displayedFilters, filterValues, showFilter } =
        useListContext();
    const { isLoading } = usePermissions();
    if (isLoading) return null;
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <SelectColumnsButton />
            <CreateButton />
        </TopToolbar>
    );
};

const PlayerFilter: React.FC<Omit<FilterProps, 'children'>> = props => {
    return (
        <Filter {...props}>
            {/* <SearchInput source="fts@name" /> */}
            <SelectInput
                label="resources.players.fields.applicationType"
                source="applicationType"
                choices={choicesApplicationType}
            />
            <SelectInput
                label="resources.players.fields.agegroup"
                source="birthdate@ilike"
                choices={choicesAgegroup}
                optionValue="value"
                alwaysOn
            />
            <TextInput
                label="resources.players.search_name"
                source="name@ilike"
                alwaysOn
            />
            <SelectInput
                source="rating"
                label="resources.players.fields.rating"
                choices={choicesRatings}
                fullWidth
            />
            <ReferenceInput reference="clubs" source="club_id">
                <AutocompleteInput
                    optionText="name"
                    label="resources.players.fields.currentClub"
                    noOptionsText="-"
                    shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                    }}
                    filterToQuery={(searchText: any) => ({
                        'name@ilike': `%${searchText}%`,
                    })}
                />
            </ReferenceInput>
            <TextInput
                label="resources.players.fields.jerseyNo"
                source="jerseyNo"
            />
            <TextInput label="resources.players.fields.city" source="city" />
            {/* <ReferenceInput
                label="resources.players.fields.positions"
                source="position_ids"
                reference="positions"
            >
                <SelectInput
                    label="resources.players.fields.positions"
                    optionText="name"
                />
            </ReferenceInput>
            <ReferenceInput
                label="resources.players.fields.repTeams"
                source="repteam_ids"
                reference="repteams"
            >
                <SelectInput
                    label="resources.players.fields.repTeams"
                    optionText="name"
                />
            </ReferenceInput> */}
            <SelectInput
                source="foot"
                label="resources.players.fields.foot"
                choices={choicesFoot}
                fullWidth
            />
        </Filter>
    );
};

export const PlayerGrid: React.FC = props => {
    const { pathname, search } = useLocation();
    const isXSmall = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("sm"),
        { noSsr: true }
    );
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    const omit = (isXSmall)
        ? ["weight", "height", "memberSince", "scouting", "positions", "repteams", "city", "currentClub", "club_id", "foot"]
        : ["weight", "height", "memberSince", "scouting", "positions", "repteams"];
    if (isLoading) return null;
    return (
        <DatagridConfigurable
            omit={omit}
            {...props}
            sx={{
                '& .RaDatagrid-headerRow': {
                    borderLeftColor: 'white',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .RaDatagrid-headerCell': {
                    padding: '6px 8px 6px 8px',
                },
                '& .RaDatagrid-rowCell': {
                    padding: '6px 8px 6px 8px',
                },
            }}
        >
            <AvatarField
                label="resources.players.fields.rating"
                sortBy="rating"
            />
            <NameField
                player_id="id"
                source="name"
                label="resources.players.fields.name"
            />
            <FunctionField
                sortBy="birthdate"
                label="resources.players.fields.birthdate"
                render={(record: any) =>
                    convertBirthdateToAge(record, 'birthdate', 'agegroup')
                }
            />
            <TextField
                label="resources.players.fields.city"
                source="city"
                sortBy="city"
                emptyText="-"
            />
            <ReferenceField
                label="resources.players.fields.currentClub"
                source="club_id"
                reference="clubs"
            >
                <TextField source="name" />
            </ReferenceField>
            <FootField
                sortBy="foot"
                source="foot"
                label="resources.players.fields.foot"
                {...props}
            />
            <TextField
                label="resources.players.fields.height"
                source="height"
                sortBy="height"
                emptyText="-"
            />
            <TextField
                label="resources.players.fields.weight"
                source="weight"
                sortBy="weight"
                emptyText="-"
            />
            <ReferenceArrayField
                label="resources.players.fields.positions"
                source="position_ids"
                reference="positions"
                sortBy="positions.name"
            >
                <SingleFieldList linkType={false}>
                    <PositionChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField
                label="resources.players.fields.repTeams"
                source="repteam_ids"
                reference="repteams"
                sortBy="repteams.name"
            >
                <SingleFieldList linkType={false}>
                    <ChipField source="slug" />
                </SingleFieldList>
            </ReferenceArrayField>
            <FunctionField
                label="resources.players.fields.memberSince"
                source="memberSince"
                sortBy="memberSince"
                render={(record: any) => {
                    if (record.memberSince) {
                        const memberSinceDate = new Date(record.memberSince);
                        const memberSince = memberSinceDate.toLocaleDateString(
                            'de-DE',
                            {
                                year: 'numeric',
                            }
                        );
                        return `${memberSince}`;
                    }
                    return '';
                }}
            />
            <FunctionField
                label="resources.players.fields.scouting"
                render={(record: any) => (
                    <TalentdayScoutingDialog
                        basepath={`${pathname}${search}`}
                        player_id={`${record.id}`}
                    />
                )}
            />
            {allowedAccess && (
                <FunctionField
                    label="resources.players.fields.contractUntil"
                    source="contractUntil"
                    sortBy="contractUntil"
                    render={(record: any) => {
                        if (record.contractUntil) {
                            const contractUntilDate = new Date(
                                record.contractUntil
                            );
                            const contractUntil =
                                contractUntilDate.toLocaleDateString('de-DE', {
                                    year: 'numeric',
                                });
                            return `${contractUntil}`;
                        }
                        return '';
                    }}
                />
            )}
        </DatagridConfigurable>
    );
};

export const PlayerMobileAndDesktopGrid = props => {
    const listContext = useListContext();
    const { data } = listContext;
    const {
        syncWithLocation,
        hasShow,
        hasList,
        hasCreate,
        hasEdit,
        hasDelete,
        omitColumns,
        ...rest
    } = props;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return isXSmall ? (
        <ResourceContextProvider value="players">
            <ListContextProvider value={{ ...listContext }}>
                <PlayerMobileGrid {...rest} data={data} />
            </ListContextProvider>
        </ResourceContextProvider>
    ) : (
        <PlayerGrid {...rest} />
    );
};

export const PlayerList: React.FC = props => {
    const storedProfile = localStorage.getItem('profile');
    // const filterDefaultValues = { applicationType: 'talentday_2022' };
    const filterDefaultValues = {};
    if (storedProfile) {
        let { agegroup } = JSON.parse(storedProfile);
        filterDefaultValues['birthdate'] = agegroup;
    }
    const filter = {
        // collectionQuery :  collection => collection.where('lastupdate', '>=', limit)
    };

    return (
        <Fragment>
            <List
                {...props}
                sx={{
                    '& .RaList-actions': {
                    },
                }}
                actions={<PlayerListActions />}
                filters={<PlayerFilter />}
                filter={filter}
                filterDefaultValues={filterDefaultValues}
                bulkActionButtons={<BulkdPlayerActionButtons />}
                sort={{ field: 'rating', order: 'DESC' }}
                perPage={25}
            >
                <PlayerMobileAndDesktopGrid {...props} />
            </List>
            {/* FIXME: 
        <Fragment>
        <RatingLegend ratings={memberRatings} id="intern" />
        <RatingLegend ratings={scoutingRatings} id="extern" />
      </Fragment> */}
        </Fragment>
    );
};
