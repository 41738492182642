import * as React from 'react';
import {
    useTranslate,
    useGetList,
    RecordContextProvider,
    useLocaleState,
    useRecordContext,
    useReference,
    RaRecord,
    useDelete,
    useNotify,
    useRefresh,
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { NoteCreate } from './NoteCreate';
import { resolveNoteTypeAttributes } from '../../types';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import { useState } from 'react';


const Aside = () => {
    const record = useRecordContext();
    return (
        <Box width={400} display={{ xs: 'none', lg: 'block' }} mt={0}>
            {record && <NotesList />}
        </Box>
    );
};

export const NotesList = () => {
    const player = useRecordContext();
    const translate = useTranslate();
    const { data: notes, refetch } = useGetList(
        'all_notes',
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'date', order: 'DESC' },
            filter: { player_id: player.id },
        }
    );

    return (
        <Box ml={2}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.notes')}
                    </Typography>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Box flexGrow={1}>
                                <NoteCreate refetch={refetch} player={player} />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {notes && <Timeline notes={notes} refetch={refetch} />}
        </Box>
    );
};

export const Timeline = ({ notes, refetch=null }: { notes: RaRecord[]; refetch?: any }) => {
    return (
    <Stepper orientation="vertical" sx={{ my: 1, ml: 1.5 }}>
        {notes.map(note => { 
            const { bgColor, icon } = resolveNoteTypeAttributes(note.type);
            return (
            <Step
                key={`${note.type}-${note.id}`}
                expanded
                active
                completed
            >
                <RecordContextProvider value={note}>
                    <StepLabel icon={icon} 
                    >
                        <NoteTitle type={note.type} refetch={refetch} />
                    </StepLabel>
                    <StepContent>
                        <Note bgColor={bgColor} />
                    </StepContent>
                </RecordContextProvider>
            </Step>
        )})}
    </Stepper>
)};

const NoteTitle = ({ type, refetch }) => {
    const [locale] = useLocaleState();
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const [isHover, setHover] = useState(false);
    const record = useRecordContext();
    const { resource, labelKey } = resolveNoteTypeAttributes(type);
    const [deleteNote] = useDelete(
        resource,
        { id: record.id, previousData: record },
        {
            mutationMode: 'pessimistic',
            onSuccess: () => {
                notify('resources.players.actions.delete_note', {
                    type: 'info',
                    undoable: true,
                });
                refetch();
                refresh();
            },
        }
    );
    const { referenceRecord } = useReference({
        reference: resource,
        id: record?.player_id,
    });
    let addendum = record.rating ? translate(`resources.rating.${record.rating}`) : ''
    addendum = resource === 'scoutings' ? translate(`resources.scoutings.types.${type}`) : addendum;
    const label = `${translate(labelKey, {
        reference: referenceRecord?.reference,
        smart_count: 1,
    })}: ${addendum}`;

    const handleDelete = () => {
        deleteNote();
    };

    if (!record) return null;
    return (
        <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                display: 'flex',
                alignItems: 'stretch',
            }}
        >
            <Box flex={1}>
                {label}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {new Date(record.date).toLocaleString(locale, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}
                </Typography>
            </Box>
            <Box
                sx={{
                    marginLeft: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    visibility: isHover ? 'visible' : 'hidden',
                }}
            >
                <Tooltip
                    title={translate(
                        'resources.players.page.edit_note'
                    )}
                >
                    <IconButton
                        size="small"
                        href={`/${resource}/${record.id}`}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={translate(
                        'resources.players.page.delete_note'
                    )}
                >
                    <IconButton 
                        onClick={handleDelete}
                        >
                        <TrashIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

const Note = ({ bgColor }) => {
    const record = useRecordContext();
    if (!record || !record.comments) return null;
    return (
        <>
            <Box
                sx={{
                    bgcolor: bgColor,
                    padding: '0.5em 1em',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'stretch',
                    marginBottom: 1,
                }}
                >
                {(
                <Box
                    sx={{
                        padding: '0 1em',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'stretch',
                        marginBottom: 1,
                    }}
                >
                    <Box flex={1}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                            // sx={{
                                // display: '-webkit-box',
                                // WebkitLineClamp: 12,
                                // WebkitBoxOrient: 'vertical',
                                // overflow: 'hidden',
                            // }}
                            gutterBottom
                        >
                            {record.comments && record.comments.split('\n').map((item, idx) => {
                                return (
                                    <span key={idx}>{item}<br /></span>
                                );
                                })}
                        </Typography>
                    </Box>
                    {/* hover edit */}
                </Box>
            )}
            </Box>
            <Typography variant="body2" color="textSecondary">
                {record.createdby}
            </Typography>
        </>
    );
};

export default Aside;