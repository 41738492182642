import React, { Fragment, useCallback, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Filter,
    Datagrid,
    EditButton,
    List,
    TextField,
    usePermissions,
    ReferenceField,
    SearchInput,
    ReferenceInput,
    SelectInput,
    DateField,
    DatagridProps,
    ListContextProvider,
    BooleanField,
    useListController,
    Count,
    TopToolbar,
    useDataProvider,
    Button,
    useNotify,
    useRefresh,
    useUnselectAll,
    useUpdateMany,
    DeleteButton,
    BulkDeleteButton,
} from 'react-admin';
import { Divider, Tabs, Tab, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ImportButton, ImportConfig } from 'react-admin-import-csv';
import moment from 'moment';
import { BulkApplicationWorkedOn } from '../applications/BulkApplicationWorkedOn';
import { BulkConvertApplication } from '../applications/BulkConvertApplication';

const ListActions = props => {
    const dataProvider = useDataProvider();
    // const notify = useNotify();
    const lookupPlayerId = async playerName => {
      const { data: playerData } = await dataProvider.getList('players', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'name', order: 'ASC' },
        filter: { name: playerName },
      });
      if (playerData.length === 0) return false;
      return playerData[0]['id'];
    };
    const lookupTrainigId = async (teamId, date, tomorrow) => {
      const { data: trainingData } = await dataProvider.getList('trainings', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'name', order: 'ASC' },
        filter: { 
            team_id: teamId, 
            'date@gte': date, 
            'date@lte': tomorrow
        },
      });
      console.log('lookupTrainigId', { trainingData });
      if (trainingData.length === 0) return null;
      return trainingData[0]['id'];
    };
    const lookupSquadplayerId = async (teamId, playerId) => {
      const { data: squadplayerData, total } = await dataProvider.getList('squadplayers', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'name', order: 'DESC' },
        filter: {
          team_id: teamId,
          player_id: playerId,
          scoutingstatus: 'member',
        },
      });
      return total === 1 ? squadplayerData[0]['id'] : null;
    };
  
    const processTrainings = async row => {
        // "team_id";"team_name";"event_id";"event_type";"event_name";"event_date_start";"event_date_end";"user_participation";"user_id";"user_name";"search_params"
      if (!row.team_id || !row.user_name || !row.event_date_start || !row.user_participation) return null;
      if (row.event_type === 'training') {
        row.id = `${row.event_id}-${row.user_id}`;
        row.name = row.user_name;
        row.lastupdate = new Date();
        const date = moment(`${row.event_date_start}`, 'DD-MM-YYYY').toISOString();
        const tomorrow = moment(`${row.event_date_start}`, 'DD-MM-YYYY').add(1, 'days').toISOString();
        row.createdby = 'SpielerPlus CSV-Import';
        row.updatedby = 'SpielerPlus CSV-Import';
        if (row.user_participation === 'STATUS_CONFIRMED') {
          row.present = 1;
          row.ill = 0;
        } else if (row.user_participation === 'STATUS_ABSENCE') {
          row.present = 0;
          row.ill = 0;
          row.away = 'abgemeldet';
        } else if (row.user_participation === 'STATUS_NOT_NOMINATED') {
          row.present = 0;
          row.ill = 0;
          row.away = 'nicht nominiert';
        } else if (row.user_participation === 'STATUS_UNSURE') {
          row.present = 0;
          row.ill = 0;
          row.light = 'angeschlagen';
        }
  
        delete row.user_name;
        delete row.user_participation;
        delete row.team_name;
        delete row.event_id;
        delete row.event_name;
        delete row.event_date_start;
        delete row.event_date_end;
        delete row.user_id;
        delete row.event_type;
        delete row.search_params;
        row.player_id = await lookupPlayerId(row.name);
        row.squadplayer_id = await lookupSquadplayerId(row.team_id, row.player_id);
        row.training_id = await lookupTrainigId(row.team_id, date, tomorrow);
        console.log('processData', { row });
        if (!row.player_id || !row.squadplayer_id || !row.training_id) return null;
      }
      return row;
    };
  
    const config: ImportConfig = {
      logging: true,
      parseConfig: {
        // For all options see: https://www.papaparse.com/docs#config
        delimiter: ';', // auto-detect
        dynamicTyping: true,
      },
      // validateRow: async (row) => {
      //   if (row && !row.id) {
      //     throw new Error("Spielkennung fehlt!");
      //   }
      // },
      preCommitCallback: async (action, values: any[]) => {
        console.log('preCommitCallback', values);
        const rows = await Promise.all(values.map(processTrainings));
        // return values;
        return rows.filter(n => n);
      },
      // transformRows: async (csvRows: any[]) => {
      //   const rows = await Promise.all(csvRows.map(getTeamId));
      //   console.log('transformRows', rows);
      //   return rows.filter(n => n);
      // },
      disableCreateMany: true,
    };
    const { className } = props;
    return (
      <TopToolbar className={className}>
        <ImportButton resourceName="trainings_players" {...config} />
      </TopToolbar>
    );
  };
  
export const TrainingFilter: React.FC = props => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput
                label="resources.trainings.fields.team_id"
                source="team_id"
                reference="teams"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ currentSeason: true }}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    );
};

interface TabbedDatagridProps extends DatagridProps {}

const DataGridContent = (props: TabbedDatagridProps) => (
    <Datagrid bulkActionButtons={<BulkTrainingsLinkedPlayersButtons />} rowClick="edit" {...props}>
        <DateField
            label="resources.trainings.fields.date"
            source="date"
            options={{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                weekday: 'short',
            }}
        />
        <TextField
            label="resources.trainings.fields.startTime"
            source="startTime"
        />
        <TextField
            label="resources.trainings.fields.endTime"
            source="endTime"
        />
        <TextField label="resources.trainings.fields.name" source="name" />,
        <ReferenceField source="team_id" reference="teams">
            <TextField
                label="resources.trainings.fields.team_id"
                source="name"
            />
        </ReferenceField>
        <BooleanField
            label="resources.trainings.fields.linkedPlayers"
            source="linkedPlayers"
        />
        <EditButton label="" />
    </Datagrid>
);

const TabbedDatagrid = (props: TabbedDatagridProps) => {
    const navigate = useNavigate();
    const listContext = useListController();
    const { data, filterValues, displayedFilters } = listContext;
    const [tab, setTab] = useState('upcoming');
    delete filterValues['date@gte'];
    delete filterValues['date@lte'];

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            if (value === 'upcoming') {
                navigate({
                    search: `displayedFilters=${encodeURI(
                        JSON.stringify(displayedFilters)
                    )}&filter=${encodeURI(
                        JSON.stringify({
                            ...filterValues,
                            'date@gte': new Date().toISOString(),
                            // 'date@lte': undefined,
                        })
                    )}&order=ASC&sort=date`,
                });
                setTab(value);
            } else if (value === 'past') {
                navigate({
                    search: `displayedFilters=${encodeURI(
                        JSON.stringify(displayedFilters)
                    )}&filter=${encodeURI(
                        JSON.stringify({
                            ...filterValues,
                            'date@lte': new Date().toISOString(),
                            // 'date@gte': undefined,
                        })
                    )}&order=DESC&sort=date`,
                });
                setTab(value);
            } else if (value === 'all') {
                navigate({
                    search: `displayedFilters=${encodeURI(
                        JSON.stringify(displayedFilters)
                    )}&filter=${encodeURI(
                        JSON.stringify({
                            ...filterValues,
                            // 'date@lte': undefined,
                            // 'date@gte': undefined,
                        })
                    )}&order=ASC&sort=id`,
                });
                setTab(value);
            }
        },
        [displayedFilters, filterValues, navigate]
    );

    const tabs = [
        {
            id: 'upcoming',
            name: 'upcoming',
            label: (
                <Typography component="span" variant="body2">
                    zukünftige Trainingseinheiten&nbsp;(
                    <Count
                        filter={{
                            ...filterValues,
                            'date@gte': new Date().toISOString(),
                        }}
                    />
                    )
                </Typography>
            ),
        },
        {
            id: 'past',
            name: 'past',
            label: (
                <Typography component="span" variant="body2">
                    vergangene Trainingseinheiten&nbsp;(
                    <Count
                        filter={{
                            ...filterValues,
                            'date@lte': new Date().toISOString(),
                        }}
                    />
                    )
                </Typography>
            ),
        },
        {
            id: 'all',
            name: 'all',
            label: (
                <Typography component="span" variant="body2">
                    alle Trainingseinheiten&nbsp;(
                    <Count filter={filterValues} />)
                </Typography>
            ),
        },
    ];

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={tab}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={choice.label}
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <ListContextProvider value={{ ...listContext, data }}>
                <DataGridContent />
            </ListContextProvider>
        </Fragment>
    );
};

export const BulkTrainingsLinkedPlayers: React.FC<{
    selectedIds: any;
  }> = ({ selectedIds }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('trainings');
    const [updateMany, { isLoading }] = useUpdateMany();
    const handleOnClick = () => {
      console.log('handleOnClick', {
        selectedIds
      }) 
      updateMany(
      'trainings', {
        ids: selectedIds,
        data: {
          linkedPlayers: true,
        },
      },
      {
        mutationMode: "pessimistic",
        onSettled: () => {
          unselectAll();
          notify(`resources.trainings.notification.linkerdPlayers`);
          refresh();
        },
        onError: () => {
          notify("resources.trainings.notification.error", {
            type: "warning",
          });
        },
      });
    }
  
    return (
      <Button label="resources.trainings.actions.linkerdPlayers" onClick={handleOnClick} disabled={isLoading}>
        <CheckCircleOutlineIcon />
      </Button>
    );
  };
  

  
const BulkTrainingsLinkedPlayersButtons = props => {
    return (
      <Fragment>
        <BulkTrainingsLinkedPlayers {...props} />
        <BulkDeleteButton {...props} />
      </Fragment>
    );
  };

export const TrainingList: React.FC = props => {
    const { isLoading } = usePermissions();
    if (isLoading) return null;
    return (
        <List
            {...props}
            perPage={25}
            actions={<ListActions />}
            filters={<TrainingFilter />}
            filterDefaultValues={{ 'date@gte': new Date().toISOString() }}
            sort={{ field: 'date', order: 'ASC' }}
        >
            <TabbedDatagrid />
        </List>
    );
};
