/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useFormContext } from "react-hook-form";
import {
  Box,
  DialogContent,
  IconButton,
  Dialog,
  DialogTitle,
  Badge,
  styled,
} from "@mui/material";
import {
  required,
  ResourceContextProvider,
  useNotify,
  usePermissions,
  useTranslate,
  useAuthProvider,
  AutocompleteInput,
  Button,
  DateInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetIdentity,
} from "react-admin";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconCancel from "@mui/icons-material/Cancel";
// import { SanitizedBox } from "../../components/SanitizedBox";
import {
  convertBirthdateToAge,
  choicesScoutingType,
  choicesScoutingReviews,
  fetchFromSupabseFunction,
} from "../../types";
import { useDataProvider, useRefresh } from "ra-core";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useMsal } from "@azure/msal-react";

const PREFIX = "TalentdayScoutingDialog";
const classes = {
  content: `${PREFIX}-content`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: 0,
  },
}));

const TalentdayScoutingDialogToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button label="ra.action.cancel" onClick={onCancel}>
      <IconCancel />
    </Button>
  </Toolbar>
);

const TalentdayScoutingSimpleForm = ({
  initialValues,
  saving,
  onCancel,
  classes,
  handleScoutingDialogFormSave,
}) => {
  return (
    <SimpleForm
      defaultValues={initialValues}
      onSubmit={handleScoutingDialogFormSave}
      classes={{ content: classes.content }}
      toolbar={
        <TalentdayScoutingDialogToolbar
          onCancel={onCancel}
          submitting={saving}
        />
      }
    >
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <ReferenceInput
          label="resources.scoutings.fields.player"
          source="player_id"
          reference="players"
          validate={[required()]}
          filterToQuery={(searchText: any) => ({ q: searchText })}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionValue="id"
            optionText={(choice) => {
              const birthdate = convertBirthdateToAge(
                choice,
                "birthdate",
                "agegroup"
              );
              return `${choice.name} - ${birthdate}`;
            }}
          />
        </ReferenceInput>
      </Box>
      <Box flex={5} mr={{ xs: 0, sm: "0.5em" }}>
        <SelectInput
          label="resources.scoutings.fields.scoutingReview"
          source="scoutingReview"
          choices={choicesScoutingReviews}
          fullWidth
        />
        <TextInput
          label="resources.scoutings.fields.report"
          source="report"
          multiline
          fullWidth
          rows={3}
        />
        <DateInput
          label="resources.scoutings.fields.date"
          source="date"
          validate={required()}
        />
        <SelectInput
          label="resources.scoutings.fields.type"
          source="type"
          fullWidth
          choices={choicesScoutingType}
        />
      </Box>
    </SimpleForm>
  );
};

const TalentdayScoutingDialogForm = ({
  onCancel,
  onSave,
  player_id,
  ...props
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { reset } = useFormContext();
  const authProvider = useAuthProvider();
  const [jwtToken, setJwtToken] = useState();
  const [, setLoading] = useState(false);
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const jwt = await authProvider.getAccessToken(msalInstance);
      setJwtToken(jwt);
    };
    fetchData();
  }, [authProvider]);

  const handleScoutingDialogFormSave = useCallback(
    async (values) => {
      setLoading(true);
      console.log("jwtToken", jwtToken);
      fetchFromSupabseFunction('talentday-scouting', { values }, jwtToken)
        .then((response) => response.json())
        .then((elem: any) => {
          if (elem.type === "create") {
            notify("resources.scoutings.notification.report_created", {
              type: "info",
              messageArgs: {
                name: elem.name,
              },
            });
          } else if (elem.type === "update") {
            notify("resources.scoutings.notification.report_updated", {
              type: "info",
              messageArgs: {
                name: elem.name,
              },
            });
          } else if (elem.type === "already_rated") {
            notify("resources.scoutings.notification.report_already", {
              type: "warning",
              messageArgs: {
                name: elem.name,
              },
            });
          }
        })
        .catch((e) => {
          notify(e.message, { type: "error" });
        })
        .finally(() => {
          setLoading(false);
          reset();
          window.scrollTo(0, 0);
          refresh();
        });
    },
    [jwtToken, notify]
  );

  const [saving] = useState();
  const { isLoading: identityLoading } = useGetIdentity();
  const initialValues = {
    date: new Date(),
    player_id: player_id,
    type: "talentday2024",
  };

  if (identityLoading) return <>Loading...</>;

  return (
    <TalentdayScoutingSimpleForm
      initialValues={initialValues}
      saving={saving}
      classes={classes}
      onCancel={onCancel}
      handleScoutingDialogFormSave={handleScoutingDialogFormSave}
    />
  );
};

interface ITalentdayScoutingDialog {
  basepath: string;
  player_id: string;
}

export const TalentdayScoutingDialog: React.FC<ITalentdayScoutingDialog> = ({
  basepath,
  player_id,
}) => {
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const handleNewClick = useCallback(
    (event) => {
      // event.stopPropagation();
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog]
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);
  const handleSave = useCallback(
    (post) => {
      setShowDialog(false);
    },
    [setShowDialog]
  );
  const [alreadyRated, setAlreadyRated] = useState(false);
  const [allRatings, setAllRatings] = useState(0);
  const { isLoading, permissions } = usePermissions();
  const dataProvider = useDataProvider();

  useEffect(() => {
    const getData = async (player_id, user) => {
      const alreadyRated = await dataProvider.getList("scoutings", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "scoutingReview", order: "DESC" },
        filter: {
          createdby: user,
          type: "talentday2024",
          player_id: player_id,
        },
      });
      const allRatings = await dataProvider.getList("scoutings", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "scoutingReview", order: "DESC" },
        filter: {
          type: "talentday2024",
          player_id: player_id,
        },
      });
      const { data: alreayRatedData } = alreadyRated;
      const { data: allRatingsData } = allRatings;
      return {
        alreayRated: alreayRatedData.length > 0,
        allRatings: allRatingsData.length,
      };
    };
    if (!isLoading && permissions.email && player_id) {
      // console.log('permissions.email',permissions.email);
      getData(player_id, permissions.email).then((result) => {
        setAlreadyRated(result.alreayRated);
        setAllRatings(result.allRatings);
      });
    }
  }, [
    alreadyRated,
    allRatings,
    permissions,
    isLoading,
    dataProvider,
    player_id,
  ]);

  const allRatingsBadge = (content, color) => (
    <Badge
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ color: `${color}` }}
      overlap="circular"
      badgeContent={`${allRatings}`}
    >
      {content}
    </Badge>
  );
  const ratingIcon = alreadyRated ? (
    <PlaylistAddCheckIcon />
  ) : (
    <PlaylistAddIcon />
  );
  const iconColor = alreadyRated ? "#006534" : "black";
  const buttonContent = allRatingsBadge(ratingIcon, iconColor);
  return (
    <Fragment>
      <Button onClick={handleNewClick}>{buttonContent}</Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleClose}
        aria-label={translate("resources.scoutings.actions.create")}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <div style={{ width: "100%" }}>
            <Box style={{ padding: 0 }} display="flex" p={1}>
              <Box p={1} flexGrow={1} style={{ padding: 0 }}>
                {translate("resources.scoutings.actions.create")}
              </Box>
              <Box p={1} style={{ padding: 0 }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          <ResourceContextProvider value="scoutings">
            <TalentdayScoutingDialogForm
              onCancel={handleClose}
              onSave={handleSave}
              player_id={player_id}
              resource="scoutings"
              basePath={basepath}
            />
          </ResourceContextProvider>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
