import { ResponsiveRadar } from "@nivo/radar"

export const ResponsiveRadarDistance = (matches_players) => {
    return (<ResponsiveRadar
        data={matches_players.filter(player => player.distance_km > 0).map(matches_player => {
            return {
                name: matches_player.name,
                distance_km_normalized: matches_player.distance_km / matches_player.time_on_pitch * 1000,
                distance_hi_km_normalized: matches_player.distance_hi_km / matches_player.time_on_pitch * 1000,
                'Distanz': matches_player.distance_km,
                'Distanz (HI)': matches_player.distance_hi_km,
            }
        })}
        keys={
            ['Distanz', 'Distanz (HI)']
        }
        indexBy="name"
        valueFormat=">-.2f"
        margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={{ scheme: 'nivo' }}
        blendMode="multiply"
        motionConfig="wobbly"
        legends={[
            {
                anchor: 'top-left',
                direction: 'column',
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />)
}