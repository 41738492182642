import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useGetIdentity,
  useUpdate,
  useRefresh,
  RaRecord,
} from "react-admin";
import moment, { Moment } from "moment";
import { supabase } from "../../supabase";

const getTrainingsInWeek = (
  currentMoment: Moment,
  dayIndex: string,
  startTime: string,
  start: Moment,
  end: Moment
) => {
  const currentWeek = currentMoment.isoWeek();
  const currentYear = currentMoment.year();
  const [hour, minute] = startTime.split(":");
  const date = moment(
    `${currentYear}-W${String(currentWeek).padStart(
      2,
      "0"
    )}-${dayIndex} ${hour}:${minute}`
  );
  if (!date.isValid() || date < start || date > end) {
    return null;
  }
  console.log("getTrainingsInWeek", {
    start: start.format("YYYY-MM-DD HH:mm"),
    date: date.format("YYYY-MM-DD dddd (d) HH:mm"),
    end: end.format("YYYY-MM-DD HH:mm"),
  });
  return date.toDate();
};

export const AddTrainingsButton: React.FC<{
  record: RaRecord;
}> = (props) => {
  const { record } = props;
  console.log("record", { record });
  const id = record.id;
  const { identity, isLoading } = useGetIdentity();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [, , practice_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const {
      startRecur,
      endRecur,
      daysOfWeek,
      startTime,
      name,
      team_id,
      endTime,
    } = record;
    const start = moment(startRecur);
    const end = moment(endRecur);
    let current = moment(startRecur);
    const trainings = [];
    while (current <= end) {
      const currentMoment = current;
      const trainingsInWeek = daysOfWeek.map((dayIndex) =>
        getTrainingsInWeek(currentMoment, dayIndex, startTime, start, end)
      );
      trainingsInWeek
        .filter((t) => t)
        .forEach((t) => {
          if (t && !isLoading) {
            const newTraining = {
              createdby: identity.fullName,
              createdate: moment().toDate(),
              lastupdate: moment().toDate(),
              updatedby: identity.fullName,
              name: name,
              team_id: team_id,
              practice_id: practice_id,
              startTime: startTime,
              endTime: endTime,
              date: t,
            };
            trainings.push(newTraining);
          }
        });
      current = currentMoment.add(7, "days");
    }
    const trainingPromises = trainings.map((training) =>
      dataProvider.create("trainings", { data: training })
    );
    await Promise.all(trainingPromises);
    update("practices", {
      id,
      data: {
        linkedTrainings: true,
      },
    });
    notify("resources.practices.notification.trainings_added", {
      type: "info",
      messageArgs: { count: trainings.length },
      undoable: false,
    });
    refresh();
    setSubmitting(false);
  }, [record, notify, update, isLoading, practice_id, dataProvider]);

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <PlaylistAddIcon />
      {translate("resources.practices.actions.trainings_added")}
    </Button>
  );
};
